import { getActivityTypeFromSlug } from "./textUtils";
import { VENUES_GRUP_TYPE_LOOKUP } from "./venueGroupsUtils";

export const parseVenueGroupSegment = (segment: string) => {
  const params =
    Object.values(VENUES_GRUP_TYPE_LOOKUP).find(({ slug }) =>
      segment.startsWith(slug + "-")
    ) || null;
  if (!params) return null;
  return {
    venuesGroupType: params.venuesGroupType,
    venuesGroupSlug: segment.replace(params.slug + "-", ""),
  };
};

export const parseRestPath = (restPath: string) => {
  const [s1, s2] = restPath.split("/") || [];
  if (s2) {
    const venueGroupParams = parseVenueGroupSegment(s1);
    const activityType = getActivityTypeFromSlug(s2);
    return venueGroupParams && activityType
      ? { ...venueGroupParams, activityType }
      : null;
  } else if (s1) {
    const activityType = getActivityTypeFromSlug(s1);
    return (
      parseVenueGroupSegment(s1) || (activityType ? { activityType } : null)
    );
  }
  return null;
};
