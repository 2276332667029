import { assignSubdomainToGlobal, getSubdomainFromGlobal } from "$lib/navUtils";
import type { Reroute } from "@sveltejs/kit";

export const reroute: Reroute = ({ url }) => {
  if (url.pathname === "/session") return;
  if (url.pathname === "/api/upload-photo") return;
  assignSubdomainToGlobal(url);
  const { subdomain } = getSubdomainFromGlobal();
  if (subdomain) return `/obiekt/${subdomain}${url.pathname}`;
};
