import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61')
];

export const server_loads = [0,5,6,7,8,9,12,11];

export const dictionary = {
		"/": [~14],
		"/(app)/admin/organizacja/[organizationId]": [15,[2,4,5],[3]],
		"/(app)/admin/organizacja/[organizationId]/przelewy-online": [~16,[2,4,5],[3]],
		"/(app)/admin/organizacja/[organizationId]/ustawienia": [17,[2,4,5],[3]],
		"/dla-biznesu": [~57,[13]],
		"/dla-biznesu/cennik": [~58,[13]],
		"/dla-biznesu/dla-kogo": [~59,[13]],
		"/dla-biznesu/funkcjonalnosci-systemu": [~60,[13]],
		"/dla-biznesu/orlik-2012": [~61,[13]],
		"/(widget)/drukuj/[calendarId]": [~51],
		"/(app)/kontakt": [~18,[2],[3]],
		"/(app)/moje-konto": [19,[2,6],[3]],
		"/(app)/moje-konto/rezerwacje": [~20,[2,6],[3]],
		"/(app)/obiekt/new/[[step]]": [34,[2],[3]],
		"/(app)/obiekt/[venueIdOrSlug]": [21,[2,7],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin": [~22,[2,7,8],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin/(venueAdminLayout)/calendars": [~27,[2,7,8,10],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin/(venueAdminLayout)/calendars/new": [~28,[2,7,8,10],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin/(calendarAdminLayout)/calendars/[calendarId]": [~23,[2,7,8,9],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin/(calendarAdminLayout)/calendars/[calendarId]/pricing": [24,[2,7,8,9],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin/(calendarAdminLayout)/calendars/[calendarId]/settings": [25,[2,7,8,9],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin/(calendarAdminLayout)/calendars/[calendarId]/widget": [26,[2,7,8,9],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin/(venueAdminLayout)/holidays": [~29,[2,7,8,10],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin/(venueAdminLayout)/metody-platnosci": [~30,[2,7,8,10],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin/(venueAdminLayout)/photos": [31,[2,7,8,10],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin/(venueAdminLayout)/promote": [32,[2,7,8,10],[3]],
		"/(app)/obiekt/[venueIdOrSlug]/admin/(venueAdminLayout)/settings": [33,[2,7,8,10],[3]],
		"/(app)/polityka-prywatnosci": [~35,[2],[3]],
		"/(app)/regulamin": [~36,[2],[3]],
		"/(widget)/rezerwujesz/[calendarId]": [~52,[12]],
		"/(widget)/rezerwujesz/[calendarId]/confirm": [53,[12]],
		"/(widget)/rezerwujesz/[calendarId]/status": [~54,[12]],
		"/(app)/super-admin": [37,[2,11],[3]],
		"/(app)/super-admin/grupy-obiektow": [~38,[2,11],[3]],
		"/(app)/super-admin/importuj-z-google": [39,[2,11],[3]],
		"/(app)/super-admin/merchanci": [~40,[2,11],[3]],
		"/(app)/super-admin/obiekty": [~41,[2,11],[3]],
		"/(app)/super-admin/organizacje": [~42,[2,11],[3]],
		"/(app)/super-admin/qr-code": [43,[2,11],[3]],
		"/(app)/super-admin/rezerwacje": [~44,[2,11],[3]],
		"/(app)/super-admin/system-stats": [45,[2,11],[3]],
		"/(app)/super-admin/uzytkownicy": [~46,[2,11],[3]],
		"/(app)/super-admin/webhooks": [~47,[2,11],[3]],
		"/(app)/super-admin/wiadomosci": [~48,[2,11],[3]],
		"/(widget)/widget/[calendarId]": [~55],
		"/(widget)/widget/[calendarId]/widget-placeholder": [~56],
		"/(app)/znajdz-obiekt": [~49,[2],[3]],
		"/(app)/znajdz-obiekt/[...restVenueParam=restVenueParam]": [~50,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';