import * as Sentry from "@sentry/sveltekit";
import { dev } from "$app/environment";
import { PUBLIC_SENTRY_DSN } from "$env/static/public";

/**
 * By leaving this comment here, dute to vite plugin
 * this log will be replaced with:
 * import MockDate from "mockdate";
 * MockDate.set("2023-05-01");
 */
console.log("INCJECT_MOCK_DATE");

if (!dev) {
  Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0,
  });
}

export const handleError = Sentry.handleErrorWithSentry();
